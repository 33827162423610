// VideoLink.tsx
import React, { useState } from "react";
// Style
import { Wrapper, Container, A, VideoContainer } from "./VideoLinkStyles";

type VideoLinkProps = {
  src: string;
};

const videoStyle = {
  maxWidth: "70vh",
  maxHeight: "70vw",
  border: "2px solid black"
};

const VideoLink: React.FC<VideoLinkProps> = ({ src }) => {
  const [showVideo, setShowVideo] = useState(false);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowVideo(!showVideo);
  };

  const handleVideoPause = () => {
    setShowVideo(false);
  };

  return (
    <Wrapper>
      <Container>
        <A href="#" onClick={handleLinkClick}>
          {showVideo ? "Stäng video" : "Introduktionsvideo"}
        </A>
        {showVideo ? (
          <VideoContainer>
            <video
              src={src}
              controls
              onEnded={handleVideoPause}
              onPause={handleVideoPause}
              autoPlay={true}
              style={videoStyle}
            />
          </VideoContainer>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default VideoLink;
