// Actions.ts
import * as SharePointType from "state/types/SharePointTypes";
// Types
import { IOptionType, IStartPageText, IScreenSize } from "state/interfaces/Interfaces";
// import { IStartPageText } from "state/interfaces/Interfaces";
// Enums
import { DISPATCHES } from "utils/Enums";

export function incramentAction(payload: number) {
  console.log("incramentAction körs!");
  return {
    type: DISPATCHES.INCREMENT,
    payload
  };
}

export function decramentAction(payload: number) {
  console.log("decramentAction körs!");
  return {
    type: DISPATCHES.DECREMENT,
    payload
  };
}

// No payload rquired
// export function countResetAction() {
//   return {
//     type: DISPATCHES.COUNT_RESET
//   };
// }

// export function setSelectedWordIndex(payload: any) {
//   console.log("setSelectedWordIndex körs!");
//   console.log("setSelectedWordIndex (id) payload: ", payload);
//   return {
//     type: DISPATCHES.SET_SELECTED_WORD_INDEX,
//     payload
//   };
// }

export function setMatchingKeyWordStrings(payload: any) {
  return {
    type: DISPATCHES.SET_MATCHING_KEYWORDS_STRINGS,
    payload
  };
}

export function setMatchingKeyWordsArray(payload: any) {
  return {
    type: DISPATCHES.SET_MATCHING_INDIVIDUAL_KEYWORDS_ARRAY,
    payload
  };
}

export function setMatchingWebSitesArray(payload: any) {
  return {
    type: DISPATCHES.SET_MATCHING_WEBSITES_ARRAY,
    payload
  };
}

export function searchResultDispatchAction(payload: any) {
  return {
    type: DISPATCHES.SEARCH_RESULT_DISPATCH_ACTION,
    payload
  };
}

// Used to set user input in state
export function searchInputAction(payload: string) {
  return {
    type: DISPATCHES.SEARCH_INPUT_SET_ACTION,
    payload
  };
}

// Used to set user selected keyword in state
export function setUserSelectedInputKeyWord(payload: IOptionType) {
  console.log("setUserSelectedInputKeyWord payload: ", payload);
  return {
    type: DISPATCHES.SET_USER_SELECTED_INPUT_KEYWORD,
    payload
  };
}

export function setHandleKeyDownInput(payload: any) {
  return {
    type: DISPATCHES.SET_HANDLE_KEY_DOWN_INPUT,
    payload
  };
}

// Används i SearchResult
// export function selectedSearchInputAction(payload: any) {
//   return {
//     type: DISPATCHES.SEARCH_INPUT_SET_ACTION,
//     payload
//   };
// }

// No payload rquired
export function searchInputResetAction() {
  console.log("searchInputResetAction() körs!");
  return {
    type: DISPATCHES.SEARCH_INPUT_RESET_ACTION
  };
}

export function searchSucessCountAction(payload: number) {
  return {
    type: DISPATCHES.SEARCH_SUCESS_COUNT_ACTION,
    payload
  };
}

export function getScreenSize(payload: IScreenSize) {
  return {
    type: DISPATCHES.GET_SCREEN_SIZE,
    payload
  };
}

// Action used to fetch SP KeyWords list data
export function getSPKeyWordsData(payload: SharePointType.IKeyWords[]) {
  return {
    type: DISPATCHES.GET_SP_KEYWORDS,
    payload
  };
}

// Action used to fetch SP Texts list data
export function getSPTextData(payload: SharePointType.ITexts[]) {
  return {
    type: DISPATCHES.GET_SP_TEXTS,
    payload
  };
}

// export function getUserSelectedInputKeyWord(payload: string) {
export function getUserSelectedInputKeyWord(payload: IOptionType) {
  return {
    type: DISPATCHES.GET_USER_SELECTED_INPUT_KEYWORD,
    payload
  };
}

// Action used in Sagas.ts to set the start page texts
export function setStartPageText(payload: IStartPageText) {
  return {
    type: DISPATCHES.SET_STARTPAGE_TEXT,
    payload
  };
}

// Action used to fetch SP WebSites list data
export function getSPWebSitesData(payload: SharePointType.IWebSites[]) {
  return {
    type: DISPATCHES.GET_SP_WEBSITES,
    payload
  };
}

// export function getSPSearchStatisticsData(payload: Ie)

// Action used to fetch SP ToolTips list data
export function getSPToolTipsData(payload: SharePointType.IToolTip[]) {
  return {
    type: DISPATCHES.GET_SP_TOOLTIPS,
    payload
  };
}

export function searchKeyWordsSaga(payload: string) {
  return {
    type: DISPATCHES.SEARCH_KEYWORD_SAGA,
    payload
  };
}

export function getSPSearchStatisticsData(payload: SharePointType.ISearchStatistics[]) {
  return {
    type: DISPATCHES.GET_SP_SEARCH_STATISTICS,
    payload
  };
}

export function searchStatisticsSaga(payload: string) {
  return {
    type: DISPATCHES.SEARCH_STATISTICS_SAGA,
    payload
  };
}

export function setClickedItem(payload: string) {
  return {
    type: DISPATCHES.SET_CLICKED_ITEM,
    payload
  };
}

export function searchResultsSaga(payload: string) {
  return {
    type: DISPATCHES.SEARCH_RESULT_SAGA,
    payload
  };
}

export function setScreenSize(payload: IScreenSize) {
  return {
    type: DISPATCHES.SET_SCREEN_SIZE,
    payload
  };
}
