// SearchResultItem.ts
import styled from "styled-components";
import { PageItemWrapper as SearchResultsWrapper } from "../../styles/helpers/ItemWrapper";

export const Wrapper = styled(SearchResultsWrapper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.2em 0.7em 0.2em 0.7em;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 0 0.2em rgba(0, 0, 0, 0.03);
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const LinkContainer = styled.a`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 0 0 0.3em;
  margin: 0 0 0.5em 0;
  cursor: pointer;
`;

export const ResultIcon = styled.img`
  width: 4em;
  height: 4em;
  border: 1px solid blue;
  border-radius: 4px;
  margin: 0.25em 0.5em 0 0;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0 0;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0 0;
`;

export const LockIconContainer = styled.div`
  margin-left: auto;
  margin-right: 0.5em;
  margin-top: 0.2em;
  /* transform: translateY(1em); */
  z-index: 9999;
`;

export const ResultItemBodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
`;

export const HyperLinkContainer = styled.div`
  margin: 0.25em 0 0 0;
  white-space: normal;
  word-break: break-word;
`;

export const ItemHeading = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.5em 0 0 0;
`;
