// Enums
export enum TOOL_TIP_TEXT {
  EXEMPEL_RUBRIK = "EXEMPEL_RUBRIK",
  VAD_VILL_DU_GORA_IDAG = "VAD_VILL_DU_GORA_IDAG",
  SOKCENTER_LINK = "SOKCENTER_LINK",
  TJANSTER_OCH_PRODUKTER_INLOGG = "TJANSTER_OCH_PRODUKTER_INLOGG",
  TJANSTER_OCH_PRODUKTER_UTAN_INLOGG = "TJANSTER_OCH_PRODUKTER_UTAN_INLOGG",
  FMVS_STOD_INOM_FORSVARSLOGISTIK = "FMVS_STOD_INOM_FORSVARSLOGISTIK",
  SKYDDAT_SOKRESULTAT = "SKYDDAT_SOKRESULTAT"
}

// TOOL_TIP_DIRECTION equals tooltip CSS class names
export enum TOOL_TIP_DIRECTION {
  LEFTUP = "tooltip-left-up",
  LEFTDOWN = "tooltip-left-down",
  RIGHTUP = "tooltip-right-up",
  RIGHTDOWN = "tooltip-right-down"
}
