// SelectSearchBarStyle.ts
import styled from "styled-components";

import { PageItemWrapper as SearchBarWrapper } from "../../styles/helpers/ItemWrapper";

export const Wrapper = styled(SearchBarWrapper)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 43em;

  @media (max-width: 950px) {
    min-width: 100vw;
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: fit-content; */
  /* border: 2px solid red; */
`;

// export const Form = styled.form`
//   display: flex;
//   flex-direction: row;
// `;

// export const Input = styled.input`
//   width: 100%;
//   min-width: 20em;
//   height: 3em;
//   border: 0px;
//   border-radius: 8px;
//   font-size: 1.2em;
//   padding-left: 0.5em;
//   background-color: #c3c2c2;
//   font-size: large;
//   font-weight: bold;
//   border: 2px solid black;

//   ::placeholder,
//   ::-webkit-input-placeholder {
//     color: #000000;
//     padding-left: 0.5em;
//   }
// `;

// export const InputRest = styled.input`
//   font-size: large;
//   font-weight: bold;
//   border: 0px;
//   background-color: rgba(0, 0, 0, 0);
//   transform: translateX(-2em);
//   min-width: 1em;
//   min-height: 1em;
// `;
