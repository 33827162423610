// GraphicalLinksStyles.ts
import styled from "styled-components";

import { PageItemWrapper as TemplateWrapper } from "../../styles/helpers/ItemWrapper";
import { CircleLink } from "styles/helpers/CircleLink";

export const Wrapper = styled(TemplateWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3em;
  width: fit-content;
  background-color: white;
`;

export const LinksUpperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: -5em;
`;

export const LinksLowerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: 8em;
  margin-top: -4em;
`;

export const Text = styled.div`
  font-size: 1.2em;
  margin-right: 1em;
  padding: 0.5em 0.1em;
`;

export const GraphicalLink = styled(CircleLink)`
  color: ${props => (props.color ? props.color : "#000000")};
  font-size: ${props => (props.fontSize ? props.fontSize : "1.2em")};
  width: 2em;
  height: 2em;
  background-color: ${props => (props.color ? props.color : "#000000")};
`;

export const LinkOuterContainer = styled.div`
  margin: 0 0.25em 0 0.25em;
`;

export const Link1OuterContainer = styled.div`
  margin: 0 2.5em 0 0.25em;
`;

export const LinkInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkText = styled.div`
  color: white;
  font-size: 1em;
  // for SP
  text-decoration: none !important;
  max-width: 7em;
`;

export const LinkArrow = styled.div`
  color: white;
  font-size: 2em;
  align-self: flex-end;
`;

export const LinkArrowSmall = styled.div`
  color: white;
  font-size: 1em;
  align-self: flex-end;
`;

export const Link1 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  background-color: #e04403;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  text-decoration: none;
  margin-bottom: 0.5em;
  margin-right: 1em;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Link2 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007167;
  width: 7em;
  height: 7em;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Link3 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #896e96;
  width: 7em;
  height: 7em;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Link4 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(12, 198, 222);
  width: 7em;
  height: 7em;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Link5 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #484b43;
  width: 7em;
  height: 7em;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Link6 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e04403;
  width: 7em;
  height: 7em;
  border-radius: 50%;
  text-decoration: none;
  padding: 1em 0 0.5em 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const LinkTextLowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 1em;
`;

// Mobile & Ipad style
export const MobileWrapper = styled(TemplateWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
`;

export const MobileUpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 2em;
`;

export const MobileMiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 2em;
`;

export const MobilelowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const MobileLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 8em 0 0 0;
`;

export const MobileLink1OuterContainer = styled.div`
  margin: 0 0 0 0;
  transform: translateX(0.5em);
  margin-top: 1em;
`;

export const MobileLink1 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  background-color: #e04403;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  text-decoration: none;
  margin-bottom: 1em;
  margin-right: 1em;
  cursor: pointer;
  border: 2px solid red;

  &:hover {
    opacity: 0.8;
  }
`;

export const MobileLinkOuterContainer = styled.div`
  margin-bottom: 1em;
`;
