// MobileGlobal.ts
import { createGlobalStyle } from "styled-components";

export const MobileGlobal = createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
        font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif !important;
        font-size: 13px;

        h1,
        .h1 {
          font-size: 36px;
        }
        h2,
        .h2 {
          font-size: 30px;
        }
        h3,
        .h3 {
          font-size: 24px;
        }
        h4,
        .h4 {
          font-size: 18px;
        }
        h5,
        .h5 {
          font-size: 14px;
        }
        h6,
        .h6 {
          font-size: 12px;
        }
        .table td {
          padding: 5px;
        }
      }
      
    #root{
        margin:0 auto;
    }
    /* .ms-siteactions-root {
      display: none !important;
    } */
    /* .ms-webpartzone-cell {
      margin: 0 0 0 0 !important;
    } */
    #contentBox {
    /* margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important; */
    margin: auto !important;
    min-width: 350px !important;
    }
    /* #button {
      min-height: 0 !important;
      min-width: 0 !important;
    } */
    /* #s4-bodyContainer {
        max-width: 350px !important;
    } */
    #s4-titlerow {
        /* max-width: 350px !important; */
        /* display: none !important; */
        margin-bottom: 27px !important;
    }
    /* #aspnetForm {
      max-width: 350px !important;
    } */
    /* * {
      max-width: 350px !important;
    } */
  /* body {
    max-width: 350px !important;
  }
/* s4-workspace
ms-core-overlay
s4-workspace.ms-core-overlay */

/* #s4-workspace.ms-core-overlay {
  width: 0 !important;
  height: 0 !important;
} */
/* #s4-workspace{
  width: 0 !important;
  height: 0 !important;
} */
.ms-core-overlay {
  width: fit-content !important;
  /* height: fit-content !important; */
}

// Får style width och height i style="" från SP?
#s4-workspace{
  width: fit-content !important;
  // Kan inte begränsa height pga att det tar bort scroll nedåt
  /* height: fit-content !important; */
}
.ms-core-overlay {
  width: fit-content !important;
  /* height: fit-content !important; */
}
.dwwAax {
  /* justify-content: flex-start !important; */
}
#contentRow {
  padding-top: 0 !important;
}
.ms-srch-sb {
  display: none !important;
}
.ms-tableCell {
  display: flex !important;
}
`;
