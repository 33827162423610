import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./state/store";
import App from "./App";
import { DISPATCHES } from "utils/Enums";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
store.dispatch({ type: DISPATCHES.INITIALIZE_SAGA });

root.render(
  <Provider store={store}>
    <div>
      <App />
    </div>
  </Provider>
);
