// VideoLinkStyle.ts
import styled from "styled-components";

import { PageItemWrapper as TemplateWrapper } from "../../styles/helpers/ItemWrapper";

export const Wrapper = styled(TemplateWrapper)``;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const A = styled.a`
  font-size: 24px;
  color: white !important;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  text-decoration: underline;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
`;

export const VideoContainer = styled.div`
  transform: translateX(-30%) translateY(1%);
`;
