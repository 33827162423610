import axios from "axios";
import $ from "jquery";

// GET()
export const get = (query: string, filter = "") => {
  return new Promise<any[]>(function (fulfill, reject) {
    axios({
      method: "get",
      url: query + filter,
      headers: { Accept: "application/json;odata=verbose" }
    }).then(
      response => {
        let items = [];
        if (response.data && response.data.d && response.data.d.results) {
          items = response.data.d.results;
        } else if (response.data && response.data.d) {
          items = response.data.d;
        }

        fulfill(items);
      },
      error => {
        reject(error);
      }
    );
  });
};

// POST()
export const post = (url: string, data: any, targetList: string) => {
  const typeString = "SP.Data." + targetList + "ListItem";
  const dataToPost = { ...data, __metadata: { type: typeString } };

  axios
    .post(url, dataToPost, {
      headers: {
        Accept: "application/json;odata=verbose",
        contentType: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose",
        "X-RequestDigest": ($ as any).find("#__REQUESTDIGEST")[0]?.value
      }
    })
    .then(
      response => {
        return response;
      },
      error => {
        console.log("axios error", error.response);
      }
    );
};

// UPDATE()
export const update = (url: string, data: any, targetList?: string) => {
  let dataToPost = { ...data };
  if (targetList) {
    const typeString = "SP.Data." + targetList + "ListItem";
    dataToPost = { ...data, __metadata: { type: typeString } };
  }
  return axios({
    method: "post",
    url: url,
    headers: {
      Accept: "application/json;odata=verbose",
      contentType: "application/json;odata=verbose",
      "Content-Type": "application/json;odata=verbose",
      "X-HTTP-Method": "MERGE",
      "X-RequestDigest": ($ as any).find("#__REQUESTDIGEST")[0]?.value,
      "If-Match": "*"
    },
    data: dataToPost
  });
};
