// Reducers.ts
import produce from "immer";
// Types
import { IOptionType, IScreenSize, ISearchResult, IStartPageText } from "state/interfaces/Interfaces";
import * as SharePointType from "state/types/SharePointTypes";
// Enums
import { DISPATCHES } from "utils/Enums";

// Default State:
// Interface for the default state
// userSelectedInputKeyWord is typed as a IOptionType to meet the data object expectations of the
// react-select library used in the SelectSearchBar component.
interface IState {
  searchInput: string;
  searchResult: ISearchResult[];
  searchSucessCount: number;
  KeyWords: SharePointType.IKeyWords[];
  Texts: SharePointType.ITexts[];
  startPageText: IStartPageText;
  selectedWordIndex: number;
  count: number;
  searchResultIndexCount: number;
  WebSites: SharePointType.IWebSites[];
  matchingKeyWords: string;
  matchingKeyWordsArray: string[];
  userSelectedInputKeyWord: IOptionType;
  matchingWebSitesObjects: SharePointType.IWebSites[];
  userKeyDownInput: string;
  AllToolTips: SharePointType.IToolTip[];
  clickedItem: string;
  SearchStatistics: SharePointType.ISearchStatistics[];
  screenSize: IScreenSize;
}

// Default State initial values
const initialDefaultState: IState = {
  searchInput: "",
  searchSucessCount: 0,
  searchResult: [],
  KeyWords: [],
  Texts: [],
  startPageText: { text_bottom: "", text_top: "" } as IStartPageText,
  selectedWordIndex: 0,
  count: 0,
  searchResultIndexCount: 0,
  WebSites: [],
  matchingKeyWords: "",
  matchingKeyWordsArray: [],
  userSelectedInputKeyWord: { label: "", value: "" } as IOptionType,
  matchingWebSitesObjects: [],
  userKeyDownInput: "",
  AllToolTips: [],
  clickedItem: "",
  SearchStatistics: [],
  screenSize: { width: 0, height: 0 }
};

// IDefaultAction have a dispatch (enum) identifier and a optional payload
export interface IDefaultAction {
  type: DISPATCHES | string;
  payload?: any;
}

export const State = (state: IState = initialDefaultState, action: IDefaultAction) => {
  return produce(state, draft => {
    switch (action.type) {
      case DISPATCHES.INCREMENT:
        // draft.count = action.payload;
        draft.searchResultIndexCount = action.payload;
        break;
      case DISPATCHES.DECREMENT:
        // draft.count = action.payload;
        draft.searchResultIndexCount = action.payload;
        break;
      case DISPATCHES.COUNT_RESET:
        // Resets to initial state values so no payload is required for reset action
        draft.count = initialDefaultState.count;
        break;
      case DISPATCHES.SET_SELECTED_WORD_INDEX:
        // console.log("case DISPATCHES.SET_SELECTED_WORD_INDEX");
        draft.selectedWordIndex = action.payload;
        break;
      case DISPATCHES.SEARCH_INPUT_SET_ACTION:
        draft.searchInput = action.payload;
        break;
      case DISPATCHES.SEARCH_RESULT_DISPATCH_ACTION:
        draft.searchResult = action.payload;
        break;
      case DISPATCHES.SEARCH_INPUT_RESET_ACTION:
        // Resets to initial state values so no payload is required for reset action
        draft.searchInput = initialDefaultState.searchInput;
        draft.searchResult = initialDefaultState.searchResult;
        draft.matchingWebSitesObjects = initialDefaultState.matchingWebSitesObjects;
        draft.searchResultIndexCount = initialDefaultState.searchResultIndexCount;
        draft.matchingKeyWordsArray = initialDefaultState.matchingKeyWordsArray;
        break;
      case DISPATCHES.SEARCH_SUCESS_COUNT_ACTION:
        draft.searchSucessCount = action.payload;
        break;
      case DISPATCHES.GET_SP_KEYWORDS:
        draft.KeyWords = action.payload;
        break;
      case DISPATCHES.GET_SP_TEXTS:
        draft.Texts = action.payload;
        break;
      case DISPATCHES.SET_STARTPAGE_TEXT:
        draft.startPageText = action.payload;
        break;
      case DISPATCHES.GET_SP_WEBSITES:
        draft.WebSites = action.payload;
        break;
      case DISPATCHES.GET_SP_TOOLTIPS:
        draft.AllToolTips = action.payload;
        break;
      case DISPATCHES.SET_MATCHING_KEYWORDS_STRINGS:
        draft.matchingKeyWords = action.payload;
        break;
      case DISPATCHES.SET_MATCHING_INDIVIDUAL_KEYWORDS_ARRAY:
        draft.matchingKeyWordsArray = action.payload;
        break;
      case DISPATCHES.SET_USER_SELECTED_INPUT_KEYWORD:
        draft.userSelectedInputKeyWord = action.payload;
        // resets the searchResultIndexCount in case the user switched between results
        // and makes a new search without hitting the x first.
        draft.searchResultIndexCount = initialDefaultState.searchResultIndexCount;
        break;
      case DISPATCHES.SET_MATCHING_WEBSITES_ARRAY:
        draft.matchingWebSitesObjects = action.payload;
        break;
      case DISPATCHES.SET_HANDLE_KEY_DOWN_INPUT:
        draft.userKeyDownInput = action.payload;
        break;
      case DISPATCHES.SET_CLICKED_ITEM:
        draft.clickedItem = action.payload;
        break;
      case DISPATCHES.GET_SP_SEARCH_STATISTICS:
        draft.SearchStatistics = action.payload;
        break;
      case DISPATCHES.SET_SCREEN_SIZE:
        draft.screenSize.width = action.payload.width;
        draft.screenSize.height = action.payload.height;
        break;
    }
  });
};
