// ToolTipComponent

// import { FaQuestionCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
// Selectors
import { getAllToolTips } from "state/selectors/Selectors";
// Utility
import { TOOL_TIP_DIRECTION } from "./ToolTipDeclarations";
// CSS
import "./ToolTipComponent.scss";

const ToolTipComponent = ({
  toolTipTextProp,
  toolTipDirectionProp,
  children
}: {
  toolTipTextProp?: string;
  toolTipDirectionProp?: TOOL_TIP_DIRECTION;
  children: any;
}) => {
  const toolTips = useSelector(getAllToolTips);

  // Variables
  let toolTipText = "";
  const toolTipDefaultText = "Hjälptext saknas";

  // Assigns toolTipText SP list text with Title as identifier
  toolTipText = toolTips.find(t => t.Title === toolTipTextProp)?.Description ?? toolTipDefaultText;

  return (
    <div>
      <div className="tooltip-container">
        {/* right down as default */}
        <span className={`tooltip-text ${toolTipDirectionProp ?? TOOL_TIP_DIRECTION.RIGHTDOWN}`}>{toolTipText}</span>
        {children}
      </div>
    </div>
  );
};

export default ToolTipComponent;
