// searchStatisticsSaga.ts
import { select, takeEvery } from "redux-saga/effects";
import * as API from "../API";
import { getSearchStatistics, getUserSelectedInputKeyWord } from "state/selectors/Selectors";
// Types / Interfaces
import * as SharePointType from "state/types/SharePointTypes";
import { IOptionType } from "state/interfaces/Interfaces";
// Enums
import { DISPATCHES } from "utils/Enums";
// Endpoint URLs
import { getEnvironment } from "utils/HelperFunctions";

interface IsearchStatisticsSagaAction {
  type: DISPATCHES;
  payload: string;
}

function* searchStatistics(action: IsearchStatisticsSagaAction) {
  // console.log("searchStatisticsSaga körs!");

  const SearchStatisticsData: SharePointType.ISearchStatistics[] = yield select(getSearchStatistics);
  // console.log("log av SearchStatisticsData från searchStatistics()", SearchStatisticsData);

  const env = getEnvironment();

  // userSelectedInputKeyWord
  const userSelectedInputKeyWord: IOptionType = yield select(getUserSelectedInputKeyWord);

  const exportObject: SharePointType.ISearchStatistics = {
    SelectedSearchWord: "",
    SelectedSearchResult: "",
    TimesSelected: 0
  };

  // The findIndex() method returns the index of the first element in an array that satisfies the provided testing function.
  // If no elements satisfy the testing function, -1 is returned.
  // If the search results does not exist (-1 is returned), creates a new entry in the SearchStatistics list.
  if (
    SearchStatisticsData.findIndex(
      s => s.SelectedSearchResult === action.payload && s.SelectedSearchWord === userSelectedInputKeyWord.value
    ) === -1
  ) {
    exportObject.SelectedSearchResult = action.payload;
    exportObject.TimesSelected = 1;
    exportObject.SelectedSearchWord = userSelectedInputKeyWord.label;

    try {
      const postURL = `${env.listapi}/getByTitle('SearchStatistics')/Items`;
      yield API.post(postURL, exportObject, "SearchStatistics");
    } catch (error) {
      console.log("SearchStatistics UPDATE error: ", error);
    }
    // else updates an already existing result
  } else {
    const index = SearchStatisticsData.findIndex(
      s => s.SelectedSearchResult === action.payload && s.SelectedSearchWord === userSelectedInputKeyWord.value
    );
    const id = SearchStatisticsData[index].ID;

    let newCount = SearchStatisticsData[index].TimesSelected;
    newCount++;
    console.log("newCount: ", newCount);

    const exportUpdateObject: Partial<SharePointType.ISearchStatistics> = {
      TimesSelected: newCount
    };

    try {
      const updateURL = `${env.listapi}/getByTitle('SearchStatistics')/Items(${id})`;
      yield API.update(updateURL, exportUpdateObject, "SearchStatistics");
    } catch (error) {
      console.log("SearchStatistics UPDATE error: ", error);
    }
  }
}

export function* searchStatisticsSaga() {
  yield takeEvery(DISPATCHES.SEARCH_STATISTICS_SAGA, searchStatistics);
}
