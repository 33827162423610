import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "../rootReducer";
import rootSaga from "../rootSaga";

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

const initialState = {};
const middleware = [logger, sagaMiddleware];

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(rootReducer, initialState, composedEnhancers);

sagaMiddleware.run(rootSaga);

export default store;
