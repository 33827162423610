// SquareLinks.tsx
import { useSelector } from "react-redux";
import { RootState } from "rootReducer";
// Icons
import logistikledning_white from "resources/icons/logistikledning_white.png";
import teknisk_tjanst_white from "resources/icons/teknisk_tjanst_white.png";
import transporttjanst_white from "resources/icons/transporttjanst_white.png";
import fornodenhetsfor_white_0214 from "resources/icons/fornodenhetsfor_white_0214.png";
import ovrig_logistik_white from "resources/icons/ovrig_logistik_white.png";
import forsvarsmedicin_white from "resources/icons/forsvarsmedicin_white.png";
// Style
import {
  Wrapper,
  Container,
  Heading,
  LinksContainer,
  Link1,
  Link2,
  Link3,
  Link4,
  Link5,
  Link6,
  LinkText,
  LinkTextOverlay,
  Icon,
  IconTransporttjanst,
  MobileWrapper,
  MobileContainer,
  MobileLinksContainer,
  MobileHeading
} from "./SquareLinksStyle";

const SquareLinks = () => {
  // Hooks
  const { screenSize } = useSelector((state: RootState) => state.State);

  if (screenSize.width > 700) {
    return (
      <Wrapper>
        <Container>
          <Heading>FMV:s stöd inom Försvarslogistik</Heading>
          <LinksContainer>
            <Link1 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/logistikledning.aspx" rel="noreferrer">
              <Icon src={logistikledning_white} alt="Logistikledning ikon" />
              <LinkTextOverlay>
                <LinkText>Logistikledning</LinkText>
              </LinkTextOverlay>
            </Link1>
            <Link2
              href="
https://logistikportalen.fmv.se/forsvarslogistik/Sidor/Transporttjanst.aspx"
              rel="noreferrer"
            >
              <IconTransporttjanst src={transporttjanst_white} alt="Transport ikon" />
              <LinkTextOverlay>
                <LinkText>Transporttjänst</LinkText>
              </LinkTextOverlay>
            </Link2>
            <Link3 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/teknisk-tjanst.aspx" rel="noreferrer">
              <Icon src={teknisk_tjanst_white} alt="Teknisk tjänst ikon" />
              <LinkTextOverlay>
                <LinkText>Teknisk tjänst</LinkText>
              </LinkTextOverlay>
            </Link3>
            <Link4
              href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/fornodenhetsforsorjning.aspx"
              rel="noreferrer"
            >
              <Icon src={fornodenhetsfor_white_0214} alt="Förnödenhetsförsörjning ikon" />

              <LinkTextOverlay>
                <LinkText>
                  Förnödenhets-
                  <br />
                  försörjning
                </LinkText>
              </LinkTextOverlay>
            </Link4>
            <Link5 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/forsvarsmedicin.aspx" rel="noreferrer">
              <Icon src={forsvarsmedicin_white} alt="Logistikledning ikon" />
              <LinkTextOverlay>
                <LinkText>Försvarsmedicin</LinkText>
              </LinkTextOverlay>
            </Link5>
            <Link6 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/ovrig-logistik.aspx" rel="noreferrer">
              <Icon src={ovrig_logistik_white} alt="Logistikledning ikon" />
              <LinkTextOverlay>
                <LinkText>Övrig logistik</LinkText>
              </LinkTextOverlay>
            </Link6>
          </LinksContainer>
        </Container>
      </Wrapper>
    );
  } else {
    return (
      <MobileWrapper>
        <MobileContainer>
          <MobileHeading>FMV:s stöd inom Försvarslogistik</MobileHeading>
          <MobileLinksContainer>
            <Link1 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/logistikledning.aspx" rel="noreferrer">
              <Icon src={logistikledning_white} alt="Logistikledning ikon" />
              <LinkTextOverlay>
                <LinkText>Logistikledning</LinkText>
              </LinkTextOverlay>
            </Link1>
            <Link2
              href="
https://logistikportalen.fmv.se/forsvarslogistik/Sidor/Transporttjanst.aspx"
              rel="noreferrer"
            >
              <Icon src={transporttjanst_white} alt="Transport ikon" />
              <LinkTextOverlay>
                <LinkText>Transporttjänst</LinkText>
              </LinkTextOverlay>
            </Link2>
            <Link3 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/teknisk-tjanst.aspx" rel="noreferrer">
              <Icon src={teknisk_tjanst_white} alt="Teknisk tjänst ikon" />
              <LinkTextOverlay>
                <LinkText>Teknisk tjänst</LinkText>
              </LinkTextOverlay>
            </Link3>
            <Link4
              href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/fornodenhetsforsorjning.aspx"
              rel="noreferrer"
            >
              <Icon src={fornodenhetsfor_white_0214} alt="Förnödenhetsförsörjning ikon" />

              <LinkTextOverlay>
                <LinkText>
                  Förnödenhets-
                  <br />
                  försörjning
                </LinkText>
              </LinkTextOverlay>
            </Link4>
            <Link5 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/forsvarsmedicin.aspx" rel="noreferrer">
              <Icon src={forsvarsmedicin_white} alt="Logistikledning ikon" />
              <LinkTextOverlay>
                <LinkText>Försvarsmedicin</LinkText>
              </LinkTextOverlay>
            </Link5>
            <Link6 href="https://logistikportalen.fmv.se/forsvarslogistik/Sidor/ovrig-logistik.aspx" rel="noreferrer">
              <Icon src={ovrig_logistik_white} alt="Logistikledning ikon" />
              <LinkTextOverlay>
                <LinkText>Övrig logistik</LinkText>
              </LinkTextOverlay>
            </Link6>
          </MobileLinksContainer>
        </MobileContainer>
      </MobileWrapper>
    );
  }
};

export default SquareLinks;
