import { put, select, takeEvery } from "redux-saga/effects";
// Selectors
import { getSearchResult, getWebSitesData } from "state/selectors/Selectors";
// Actions
import * as Action from "state/actions/Actions";
// Types / Interfaces
import * as SharePointType from "state/types/SharePointTypes";
// Enums
import { DISPATCHES } from "utils/Enums";
import { ISearchResult } from "state/interfaces/Interfaces";

interface InputKeyWord {
  type: DISPATCHES;
  payload: string;
}

// searchResultsSaga()
// Compares keyword data and user input to return search results
// searchResultsSaga() is called from the SelectSearchBar component
// with the user selected result item label (selectedOption.label) as input
function* searchResults(action: InputKeyWord) {
  const searchResults: ISearchResult[] = yield select(getSearchResult);

  interface KeyWordWebDataItem {
    KeyWord: string;
    WebSiteURLIndex: string;
  }

  const keyWordWebDataItemList: KeyWordWebDataItem[] = Array(0);

  searchResults.forEach(item => {
    if (item.KeyWords) {
      const keyWords: string[] = item.KeyWords.split(";");
      keyWords.forEach(keyword => {
        if (item.WebSiteURLIDIndex) {
          const WebSiteURLIDIndexes: string[] = item.WebSiteURLIDIndex.split(";");
          WebSiteURLIDIndexes.forEach(index => {
            if (keyWordWebDataItemList.findIndex(k => k.KeyWord === keyword && k.WebSiteURLIndex === index) === -1) {
              keyWordWebDataItemList.push({
                KeyWord: keyword,
                WebSiteURLIndex: index
              });
            }
          });
        }
      });
    }
  });

  const WebSitesData: SharePointType.IWebSites[] = yield select(getWebSitesData);

  // tempSearchString holds the user input
  // in form of the action payload
  const tempSearchString = action.payload;

  const keyWordResults = keyWordWebDataItemList.filter(
    item => item.KeyWord.toLowerCase() === tempSearchString.toLowerCase()
  );

  // Loops through keyWordResults
  // Separates indexes (by ;) into new keWordIndexes string array
  const matchingWebSitesObjects: SharePointType.IWebSites[] = [];

  // console.log("TTT keyWordResults: ", keyWordResults);

  keyWordResults.forEach(keyWordItem => {
    const webSite = WebSitesData.find(item => item.WebSiteURLIDIndex === keyWordItem.WebSiteURLIndex);
    if (webSite) {
      if (matchingWebSitesObjects.findIndex(m => m.WebSiteURLIDIndex === webSite.WebSiteURLIDIndex) === -1) {
        matchingWebSitesObjects.push(webSite);
      }
    }
  });

  yield put(Action.setMatchingWebSitesArray(matchingWebSitesObjects));
}

export function* searchResultsSaga() {
  yield takeEvery(DISPATCHES.SEARCH_RESULT_SAGA, searchResults);
}
