// prod, dev, local
// export const getEnvironment = () => {
//   const o = {
//     baseUrl: "/startsida/",
//     listapi: "/startsida/_api/web/lists",
//     webapi: "/startsida/_api/web",
//     iconURL: "/startsida/Icons/",
//     env: "local"
//   };

//   if (window.location.host.includes("localhost")) {
//     return o;
//   }

//   // logp dev
//   if (window.location.pathname.search("/tjansterprodukter/dev") !== -1) {
//     // o.baseUrl = "/tjansterprodukter/dev/startsida";
//     o.baseUrl = "/startsida/dev/";
//     o.listapi = "/startsida/dev/_api/web/lists";
//     o.webapi = "/startsida/dev/_api/web";
//     o.iconURL = "/startsida/dev/Icons/";
//     o.env = "dev";
//   } else {
//     o.baseUrl = "/";
//     o.listapi = "/_api/web/lists";
//     o.webapi = "/_api/web";
//     o.iconURL = "/Icons/";
//     o.env = "prod";
//   }

//   return o;
// };

// för prod miljö t.ex. "startsida ikoner"

export const getEnvironment = () => {
  const o = {
    baseUrl: "/startsida/",
    listapi: "/startsida/_api/web/lists",
    webapi: "/startsida/_api/web",
    iconURL: "/startsida/Icons/",
    env: "local"
  };

  if (window.location.host.includes("localhost")) {
    return o;
  }

  // logp dev
  if (window.location.pathname.search("/tjansterprodukter/dev") !== -1) {
    // o.baseUrl = "/tjansterprodukter/dev/startsida";
    o.baseUrl = "/tjansterprodukter/dev/";
    o.listapi = "/tjansterprodukter/dev/_api/web/lists";
    o.webapi = "/tjansterprodukter/dev/_api/web";
    o.iconURL = "/tjansterprodukter/dev/Icons/";
    o.env = "dev";
  } else {
    o.baseUrl = "/";
    o.listapi = "/_api/web/lists";
    o.webapi = "/_api/web";
    o.iconURL = "/Icons/";
    o.env = "prod";
  }

  return o;
};
