// StartPageMobileStyle.ts
import styled from "styled-components";
// images
import StartImageDefault from "resources/images/StartImageDefault.png";
// icons
import logistikportalen_white from "resources/icons/logistikportalen_white.png";

// Layout containers
export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

// End of layout containers

// Top banner image style
export const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 12em;
  background-image: url(${StartImageDefault});
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const ImgInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: inherit;
`;

export const GraphicalLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 2em;
`;

export const SquareLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 2em;
`;

export const QuickLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-bottom: 6em;
`;

// Logotype style
export const LogoTypeContainer = styled.div`
  width: 18em;
  height: 12em;
  background-image: url(${logistikportalen_white});
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

// Video link style
export const VideoLinkContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 25%;
  height: 15em;
  width: 15em;
  z-index: 9999;
`;

// Text style
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  justify-content: center;
  padding: 1em 1em 0 1em;
  margin-bottom: 2em;
`;

export const Text = styled.div`
  word-break: keep-all;
  margin: 0 0.2em 0.2em 0;
`;

export const UL = styled.ul`
  padding: 0 0 1em 2em;
`;

export const LI = styled.li`
  margin: 0 0 0.5em 0;
`;

// Links style
export const ReadMoreLink = styled.a`
  cursor: pointer;

  &:hover {
    color: #0000ee;
  }
`;
