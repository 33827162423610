import styled from "styled-components";

import { PageItemWrapper as StartPageWrapper } from "../../styles/helpers/ItemWrapper";
// images
import StartImageDefault from "resources/images/StartImageDefault.png";
// import StartPageImageWide from "resources/images/StartPageImageWide.png";
// icons
import logistikportalen_white from "resources/icons/logistikportalen_white.png";

// Layout containers
// makes up the basic layout and positioning
export const Wrapper = styled(StartPageWrapper)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  // Have width/height to be % for \DMZ
  // can't handle height vh/vw values in thiscontext
  height: 100%;
  margin: 0 0 0 0;
  background-color: #f2f1f1;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  // Have width/height to be % for \DMZ
  // can't handle height vh/vw values in this context
  height: 100%;
  align-items: flex-start;
  background-color: #ffffff;
  border-top: none;
  border-bottom: none;
`;

export const InnerUpperRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 1080px;
  margin-top: 1em;
  background-color: #ffffff;
  border-right: 1em solid white;
  border-left: 1em solid white;
`;

export const InnerLowerRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 1080px;
  background-color: #ffffff;
  margin-top: 1em;
  border-right: 1em solid white;
  border-left: 1em solid white;
  border-bottom: 1em solid white;
`;

export const LeftUpperColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 54%;
  background-color: #ffffff;
`;

export const RightUpperColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44%;
  padding-left: 2%;
  background-color: #ffffff;
`;

export const LeftLowerColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 56%;
  padding-right: 1%;
  background-color: #ffffff;
`;

export const RightLowerColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 42%;
  padding-left: 1%;
  background-color: #ffffff;
`;
// End of layout containers

// Top banner image style
export const ImgContainer = styled.div`
  position: relative;
  width: 1080px;
  height: 17em;
  background-image: url(${StartImageDefault});
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const ImgInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 17em;
`;

export const Heading = styled.h1`
  text-align: center;
  color: #ffffff;
  margin: 1em 0 0 0;
`;

// Logotype style
export const LogoTypeContainer = styled.div`
  width: 60%;
  height: 30vh;
  background-image: url(${logistikportalen_white});
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

// Video link style
export const VideoLinkContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 25%;
  height: 5em;
  width: 15em;
  z-index: 9999;
`;

// Text style
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 1em 0 1em;
`;

export const Text = styled.div`
  padding-bottom: 1em;
`;

export const UL = styled.ul`
  padding: 0 0 1em 2em;
`;

export const LI = styled.li`
  margin: 0 0 0.5em 0;
`;

// Links style
export const ReadMoreLink = styled.a`
  cursor: pointer;

  &:hover {
    color: #0000ee;
  }
`;
