// SearchResultItem.tsx
// Component takes the title, url and img string props to create a search result item.
// description is kept disabled, for possible future implementation.
// Components
import ToolTipComponent from "components/ToolTipComponent/ToolTipComponent";
import { TOOL_TIP_DIRECTION, TOOL_TIP_TEXT } from "components/ToolTipComponent/ToolTipDeclarations";
// Style
import * as Action from "state/actions/Actions";
import { useDispatch } from "react-redux";
import {
  Wrapper,
  Container,
  // ResultText,
  ResultIcon,
  ItemHeading,
  HyperLinkContainer,
  // HeadingContainer,
  ResultItemBodyContainer,
  IconContainer,
  LinkContainer,
  LockIconContainer
} from "./SearchResultItemStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

interface SearchResultItemProps {
  // Id: number;
  title: string;
  // description: string | undefined;
  url: string;
  img: string;
  protected: boolean;
}

const SearchResultItem: React.FC<SearchResultItemProps> = (props: SearchResultItemProps) => {
  const dispatch = useDispatch();

  // Capitalize first letter in keyword, that's used for result item heading.
  const lowerCaseKeyWord = props.title;
  const keyWordFirstLetter = lowerCaseKeyWord.charAt(0).toUpperCase();
  const resultItemHeading = keyWordFirstLetter + lowerCaseKeyWord.slice(1);

  // removes the /PublishingImages/ part of the SiteImage string
  // to be used as img alt text
  const altImgText = props.img.substring(18);

  const handleItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const id = event.currentTarget.getAttribute("id");
    if (id) {
      dispatch(Action.setClickedItem(id));

      dispatch(Action.searchStatisticsSaga(id));
    }
  };

  return (
    <div id={props.title} onClick={handleItemClick}>
      <Wrapper>
        <Container>
          {/* With target="blank" for dev purposes */}
          {/* <LinkContainer href={props.url} target="blank" rel="noreferrer"> */}
          {/* Live build should use the following without target="blank" */}
          <LinkContainer href={props.url} rel="noreferrer">
            <IconContainer>
              <ResultIcon src={props.img} alt={altImgText} />
              <ItemHeading>{[resultItemHeading]}</ItemHeading>
            </IconContainer>
            <ResultItemBodyContainer>
              <div>
                <HyperLinkContainer>{props.url}</HyperLinkContainer>
              </div>
            </ResultItemBodyContainer>
          </LinkContainer>
          <LockIconContainer>
            <ToolTipComponent
              toolTipTextProp={TOOL_TIP_TEXT.SKYDDAT_SOKRESULTAT}
              toolTipDirectionProp={TOOL_TIP_DIRECTION.LEFTDOWN}
            >
              {props.protected ? <FontAwesomeIcon icon={faLock} size="lg" color="black" /> : null}
            </ToolTipComponent>
          </LockIconContainer>
        </Container>
      </Wrapper>
    </div>
  );
};

export default SearchResultItem;
