// SearchResultCarousel.tsx
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "rootReducer";
// Components
import SearchResultItem from "components/searchResultItem/SearchResultItem";
// Style
import {
  Wrapper,
  Container,
  ResultItemContainer,
  PrevBtn,
  NextBtn,
  HelpTextContainer,
  SearchNumberText
} from "./SearchResultCarouselStyles";
// Actions
import { decramentAction, incramentAction } from "state/actions/Actions";
import { MouseEvent } from "react";

const SearchResultCarousel = () => {
  const dispatch = useDispatch();
  // Hooks
  const { matchingWebSitesObjects } = useSelector((state: RootState) => state.State);
  const { searchResultIndexCount } = useSelector((state: RootState) => state.State);

  // Functions
  // Check to prevent count being less than zero
  // not allowed to exceed 1/3 of matchingWebSitesObjects.length
  // IF click-event does not exceed lenght +3, to prevent that index exceed max number of results
  const increment = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const tempCount = searchResultIndexCount + 3;

    dispatch(incramentAction(tempCount));
  };

  const decrement = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const tempCount = searchResultIndexCount - 3;
    dispatch(decramentAction(tempCount));
  };

  const showPrevBtn = () => {
    if (searchResultIndexCount <= 0) {
      return false;
    }
    return true;
  };

  const showNextBtn = () => {
    const remainderResult = matchingWebSitesObjects.length % 3;
    let maxIndex = Math.floor(matchingWebSitesObjects.length / 3) * 3;
    maxIndex = remainderResult === 0 ? maxIndex - 3 : maxIndex;
    if (maxIndex === searchResultIndexCount) {
      return false;
    }

    if (matchingWebSitesObjects.length > 3) {
      return true;
    }

    return false;
  };

  const showHelpText = () => {
    const remainderResult = matchingWebSitesObjects.length % 3;
    let maxIndex = Math.floor(matchingWebSitesObjects.length / 3) * 3;
    maxIndex = remainderResult === 0 ? maxIndex - 3 : maxIndex;
    if (maxIndex === searchResultIndexCount) {
      return true;
    }
    return false;
  };

  if (matchingWebSitesObjects.length > 0) {
    return (
      <Wrapper>
        <Container>
          {showPrevBtn() ? <PrevBtn onClick={(e: MouseEvent<HTMLElement>) => decrement(e)}>&#8249;</PrevBtn> : null}
          <ResultItemContainer>
            {matchingWebSitesObjects.length === 1 ? (
              <SearchNumberText>Din sökning ger 1 träff:</SearchNumberText>
            ) : (
              <SearchNumberText>Din sökning ger {matchingWebSitesObjects.length} träffar:</SearchNumberText>
            )}
            {matchingWebSitesObjects.slice(searchResultIndexCount, searchResultIndexCount + 3).map(viewItem => (
              <div key={viewItem.ID}>
                {/* <SearchResultItem title={viewItem.Title} url={viewItem.URL} img={viewItem.SiteImage} /> */}
                <SearchResultItem
                  title={viewItem.Title}
                  url={viewItem.URL}
                  img={viewItem.SiteImage}
                  protected={viewItem.Protected}
                />
              </div>
            ))}
            {showHelpText() ? (
              <HelpTextContainer>
                Hittar du inte det du letar efter? Klicka här för att komma till kundtjänst och ställ en fråga.{" "}
                <a
                  href="https://logistikportalen.fmv.se/kundtjanst/Sidor/Kundtjanst.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  [FMV Kundtjänst Verksamhetsstöd]
                </a>
              </HelpTextContainer>
            ) : null}
          </ResultItemContainer>
          {showNextBtn() ? <NextBtn onClick={(e: MouseEvent<HTMLElement>) => increment(e)}>&#8250;</NextBtn> : null}
        </Container>
      </Wrapper>
    );
  }

  // Default return, used if no search has been made or there is no matching search results
  return (
    <Wrapper>
      <Container>
        {/* {userSelectedInputKeyWord.value ? <div>Din sökning ger {matchingWebSitesObjects.length} träffar.</div> : null} */}
      </Container>
    </Wrapper>
  );
};

export default SearchResultCarousel;
