import styled from "styled-components";

import { PageItemWrapper as TemplateWrapper } from "../../styles/helpers/ItemWrapper";
import { QiuckLink } from "styles/helpers/QuickLink";
// import { DefaultTheme } from "styles/global";

export const Wrapper = styled(TemplateWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 33em;
  background-color: white;
`;

export const Heading = styled.div`
  background-color: #000000;
  color: white;
  padding: 0.5em 1em 0.5em 1em;
`;

export const UL = styled.ul`
  padding: 1em 0 0 1em;
  list-style-type: none;
`;

export const LI = styled.li`
  text-decoration: underline;
  margin-bottom: 0.5em;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 1.2em;
`;

export const A = styled.a`
  text-decoration: none !important;
`;

export const LinkText = styled(QiuckLink)`
  color: #000000;
  text-decoration: underline;

  &:hover {
    color: #0000ff;
  }
`;

// Mobile & Ipad style
export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: white;
  margin-bottom: 1em;
`;

export const MobileHeading = styled.div`
  background-color: #000000;
  color: white;
  padding: 0.5em 1em 0.5em 1em;
  margin: 0 0.75em 0 0.75em;

  // for Galaxy Fold
  @media (max-width: 300px) {
    margin: 0 0 0 0;
  }
`;
