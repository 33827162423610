import { call, put, takeEvery } from "redux-saga/effects";
// Types / Interfaces
import * as SharePointType from "state/types/SharePointTypes";
import { IScreenSize, IStartPageText } from "state/interfaces/Interfaces";
// Actions
import * as Action from "state/actions/Actions";
// API
import * as API from "../API";
// Endpoint URLs
import * as URL from "utils/EndPoints";
// Enums
import { DISPATCHES } from "utils/Enums";

function* initializeApplication() {
  // yield call for SP KeyWords list data
  const KeyWordsData: SharePointType.IKeyWords[] = yield call(API.get, URL.keyWordsURL);
  yield put(Action.getSPKeyWordsData(KeyWordsData));

  // yield call for SP Texts list data
  const TextsData: SharePointType.ITexts[] = yield call(API.get, URL.TextURL);
  yield put(Action.getSPTextData(TextsData));

  // yield call for SP WebSites list data
  const WebSitesData: SharePointType.IWebSites[] = yield call(API.get, URL.WebSitesURL);
  yield put(Action.getSPWebSitesData(WebSitesData));

  // yield call for SP ToolTips list data
  const ToolTipsData: SharePointType.IToolTip[] = yield call(API.get, URL.ToolTipsURL);
  yield put(Action.getSPToolTipsData(ToolTipsData));

  // yield call for SP SearchStatistics list data
  const SearchStatisticsData: SharePointType.ISearchStatistics[] = yield call(API.get, URL.SearchStatistics);
  yield put(Action.getSPSearchStatisticsData(SearchStatisticsData));

  // loops through text data and sets the upper and lower startpage texts
  // text.Title used in the switch is the SP list heading/title (rubrik) column
  const StartPageText: IStartPageText = { text_top: "", text_bottom: "" };

  TextsData.forEach(text => {
    switch (text.Title) {
      case "TEXT_TOP":
        StartPageText.text_top = text.Text;
        break;
      case "TEXT_BOTTOM":
        StartPageText.text_bottom = text.Text;
        break;
    }
  });

  yield put(Action.setStartPageText(StartPageText));

  // Sets screen wdith and height in state, used in app to set desktop or mobile styling
  const width = window.innerWidth;
  document.documentElement.clientWidth;
  document.body.clientWidth;

  const height = window.innerHeight;
  document.documentElement.clientHeight;
  document.body.clientHeight;

  console.log("width: ", width);
  console.log("height: ", height);

  const screenSizePayload: IScreenSize = { width: width, height: height };

  yield put(Action.setScreenSize(screenSizePayload));
}

export function* initializeApplicationSaga() {
  yield takeEvery(DISPATCHES.INITIALIZE_SAGA, initializeApplication);
}
