// SquareLinksStyle.ts
import styled from "styled-components";

import { PageItemWrapper as TemplateWrapper } from "../../styles/helpers/ItemWrapper";

export const Wrapper = styled(TemplateWrapper)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;

  @media (min-width: 768px) {
    width: 100%;
    margin: 0 1em 0 1em;
  }
`;

// Add flex wrap to make the links expand vertically
export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  // Flex-wrap for the broader icon layout
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  width: auto;
`;

export const Heading = styled.div`
  background-color: #000000;
  color: #ffffff;
  padding: 0.5em 0 0.5em 1em;
`;

export const Link1 = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000000;
  height: 15em;
  width: 16.67%;
  margin: 0.5em 0.5em 0 0;
  padding-top: 2em;
  padding-bottom: 0.5em;
  font-size: x-small;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    width: 45%;
  }
`;

export const Link2 = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000000;
  height: 15em;
  width: 16.67%;
  margin: 0.5em 0.5em 0 0;
  padding-top: 2em;
  padding-bottom: 0.5em;
  font-size: x-small;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    width: 45%;
  }
`;

export const Link3 = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000000;
  height: 15em;
  width: 16.67%;
  margin: 0.5em 0.5em 0 0;
  padding-top: 2em;
  padding-bottom: 0.5em;
  font-size: x-small;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    width: 45%;
  }
`;

export const Link4 = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000000;
  height: 15em;
  width: 16.67%;
  margin: 0.5em 0.5em 0 0;
  padding-top: 2em;
  padding-bottom: 0.5em;
  font-size: x-small;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    width: 45%;
  }
`;

export const Link5 = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000000;
  height: 15em;
  width: 16.67%;
  margin: 0.5em 0.5em 0 0;
  padding-top: 2em;
  padding-bottom: 0.5em;
  font-size: x-small;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    width: 45%;
  }
`;

export const Link6 = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000000;
  height: 15em;
  width: 16.67%;
  margin: 0.5em 0 0 0;
  padding-top: 2em;
  padding-bottom: 0.5em;
  font-size: x-small;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    width: 45%;
  }
`;

export const LinkText = styled.div`
  align-self: center;
  margin: 0.5em 0 0.5em 0;
  color: white;
  text-decoration: none !important;
`;

export const LinkTextOverlay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 3em;
  width: 100%;
`;

export const Icon = styled.img`
  width: 4em;
  height: 4em;
  align-self: center;
  margin-bottom: 2em;
`;

export const IconTransporttjanst = styled.img`
  width: 3em;
  height: 4em;
  align-self: center;
  margin-bottom: 2em;
`;

export const A = styled.a`
  text-decoration: none !important;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

// Mobile & Ipad style

export const MobileWrapper = styled(TemplateWrapper)`
  display: flex;
  flex-direction: row;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 100vw;
`;

export const MobileLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-bottom: 1em;
`;

export const MobileHeading = styled.div`
  background-color: #000000;
  color: #ffffff;
  padding: 0.5em 0 0.5em 1em;
  margin: 0 0.75em 0 0.75em;

  // for Galaxy Fold
  @media (max-width: 300px) {
    margin: 0 0 0 0;
  }
`;
