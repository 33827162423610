// SearchResultCarouselStyles.ts
import styled from "styled-components";

import { PageItemWrapper as TemplateWrapper } from "../../styles/helpers/ItemWrapper";

export const Wrapper = styled(TemplateWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 43em;
  background-color: #ffffff;
  /* border: 1px solid blue; */

  @media (max-width: 900px) {
    min-width: 100vw;
  }
`;

export const ResultItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PrevBtn = styled.button`
  /* min-width: 1.05em; */
  // width, max and min values with !important to overwrite SharePoint button values
  width: 1.05em !important;
  min-width: 1.05em !important;
  max-width: 1.05em !important;
  // Total min-height based on the total height of 3 search results.
  min-height: 8em;
  max-height: 10em;
  color: #000;
  font-size: 36px;
  /* border: 1px solid black; */
  border: none;
  outline: none;
  background-color: #dfdfdf;
  cursor: pointer;
  /* border: 2px solid red; */
  // just SharePoint things
  margin-left: 0px !important;
`;

export const NextBtn = styled.button`
  /* min-width: 1.05em; */
  /* max-width: 10em; */
  // width, max and min values with !important to overwrite SharePoint button values
  width: 1.05em !important;
  min-width: 1.05em !important;
  max-width: 1.05em !important;
  // Total min-height based on the total height of 3 search results.
  min-height: 8em;
  max-height: 10em;
  color: #000;
  font-size: 36px;
  /* border: 1px solid black; */
  border: none;
  outline: none;
  background-color: #dfdfdf;
  cursor: pointer;
  /* border: 2px solid red; */
`;

export const HelpTextContainer = styled.div`
  margin: 1em 0.5em 0 0.5em;
`;

export const SearchNumberText = styled.div`
  padding-left: 0.5em;
`;
