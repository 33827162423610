// Enums
export enum DISPATCHES {
  // NOT IN USE
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
  COUNT_RESET = "COUNT_RESET",
  SELECTED_W0RD_INDEX = "SELECTED_W0RD_INDEX",
  // ACTIONS
  SEARCH_INPUT_ACTION = "SEARCH_INPUT_ACTION",
  SEARCH_RESULT_DISPATCH_ACTION = "SEARCH_RESULT_DISPATCH_ACTION",
  SEARCH_INPUT_SET_ACTION = "SEARCH_INPUT_SET_ACTION",
  SEARCH_INPUT_RESET_ACTION = "SEARCH_INPUT_RESET_ACTION",
  SEARCH_SUCESS_COUNT_ACTION = "SEARCH_SUCESS_COUNT_ACTION",
  SET_USER_SELECTED_INPUT_KEYWORD = "SET_USER_SELECTED_INPUT_KEYWORD",
  SET_CLICKED_ITEM = "SET_CLICKED_ITEM",
  SET_SCREEN_SIZE = "SET_SCREEN_SIZE",
  GET_SCREEN_SIZE = "GET_SCREEN_SIZE",
  // SAGAS
  INITIALIZE_SAGA = "INITIALIZE_SAGA",
  SEARCH_KEYWORD_SAGA = "SEARCH_KEYWORD_SAGA",
  SEARCH_RESULT_SAGA = "SEARCH_RESULT_SAGA ",
  SEARCH_STATISTICS_SAGA = " SEARCH_STATISTICS_SAGA",
  // SET
  SET_SELECTED_WORD_INDEX = "SET_SELECTED_WORD_INDEX",
  SET_STARTPAGE_TEXT = "SET_STARTPAGE_TEXT",
  SET_MATCHING_KEYWORDS_STRINGS = "SET_MATCHING_KEYWORDS_STRINGS",
  SET_MATCHING_INDIVIDUAL_KEYWORDS_ARRAY = " SET_MATCHING_INDIVIDUAL_KEYWORDS_ARRAY",
  SET_MATCHING_WEBSITES_ARRAY = "SET_MATCHING_WEBSITES_ARRAY",
  SET_HANDLE_KEY_DOWN_INPUT = "SET_HANDLE_KEY_DOWN_INPUT",
  // SP DISPATCHES
  GET_SP_KEYWORDS = "GET_SPLIST_KEYWORDS",
  GET_SP_TEXTS = "GET_SP_TEXTS ",
  GET_SP_WEBSITES = "GET_SP_WEBSITES",
  GET_SP_TOOLTIPS = "GET_SP_TOOLTIPS",
  GET_USER_SELECTED_INPUT_KEYWORD = "GET_USER_SELECTED_INPUT_KEYWORD",
  GET_SP_SEARCH_STATISTICS = "GET_SP_SEARCH_STATISTICS"
}

export enum RequestType {
  NEW = "NEW",
  UPDATE = "UPDATE",
  NONE = "NONE"
}
