import { RootState } from "../../rootReducer";

// searchInput
export const getUserInput = (state: RootState) => state.State.searchInput;
// searchResult
export const getSearchResult = (state: RootState) => state.State.searchResult;
// KeyWords
export const getKeyWordsData = (state: RootState) => state.State.KeyWords;
// WebSites
export const getWebSitesData = (state: RootState) => state.State.WebSites;
// userSelectedInputKeyWord
export const getUserSelectedInputKeyWord = (state: RootState) => state.State.userSelectedInputKeyWord;
// ToolTip
export const getAllToolTips = (state: RootState) => state.State.AllToolTips;
// clickedItem
export const getClickedItem = (state: RootState) => state.State.clickedItem;
// SearchStatistics
export const getSearchStatistics = (state: RootState) => state.State.SearchStatistics;
