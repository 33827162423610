import { put, select, takeEvery } from "redux-saga/effects";
// Actions
import * as Action from "state/actions/Actions";
// Selectors
import { getKeyWordsData } from "state/selectors/Selectors";
// Types / Interfaces
import * as SharePointType from "state/types/SharePointTypes";
// Enums
import { DISPATCHES } from "utils/Enums";

interface setUserInputString {
  type: DISPATCHES;
  payload: string;
}

function* searchKeyWords(action: setUserInputString) {
  yield put(Action.searchInputAction(action.payload));

  const keyWordsData: SharePointType.IKeyWords[] = yield select(getKeyWordsData);
  console.log("keyWordsData: ", keyWordsData);

  let searchResult: any[] = [];
  // if to prevent sorting before user typed at least 3 letters
  // if (userInputData.searchInput.length > 2) {
  if (action.payload.length >= 2) {
    // returns search results based on keywords
    const tempSearchString = action.payload;

    // Filters SP keywords data with user input:
    // toLowerCase for handling lower case (swe: gemener) and capital letter (swe: versaler) matches
    // Additional check if item.KeyWords && tempSearchString is truthy.
    // searchResult = keyWordsData.filter(
    //   item => item.KeyWords && tempSearchString && item.KeyWords.toLowerCase().includes(tempSearchString.toLowerCase())
    // );

    searchResult = keyWordsData.filter(item => {
      console.log("item.KeyWords:", item.KeyWords);
      console.log("tempSearchString:", tempSearchString);

      return item.KeyWords && tempSearchString && item.KeyWords.toLowerCase().includes(tempSearchString.toLowerCase());
    });

    // sets search result in state
    yield put(Action.searchResultDispatchAction(searchResult));

    // matchingKeyWordStrings
    const matchingKeyWordStrings: string[] = [];

    // loops through the searchResult and pushes the keywords into the matchingKeyWordStrings array
    for (const item of searchResult) {
      if (item.KeyWords.toLowerCase().includes(tempSearchString.toLowerCase())) {
        matchingKeyWordStrings.push(item.KeyWords);
      }
    }

    // sets the matching strings of ";" separated keywords in state
    yield put(Action.setMatchingKeyWordStrings(matchingKeyWordStrings));

    // Loops through the matchingKeyWordStrings
    // and splits the strings at ";"
    // pushes result in new matchingIndividualKeyWordsArray
    // where each aray index is an individual keyWord match.
    // Used by the SelectSearchBar component to set OptionType values,
    // words that's displayed to the user.
    const matchingIndividualKeyWordsArray: string[] = [];

    matchingKeyWordStrings.forEach((str: string) => {
      const words: string[] = str.split(";");
      words.forEach((word: string) => {
        // IF word does not exist in matchingIndividualKeyWordsArry adds word
        // used to remove duplicates
        if (matchingIndividualKeyWordsArray.indexOf(word.toUpperCase()) === -1) {
          matchingIndividualKeyWordsArray.push(word.toUpperCase());
        }
      });
    });

    yield put(Action.setMatchingKeyWordsArray(matchingIndividualKeyWordsArray));
  }
}

export function* searchKeyWordsSaga() {
  yield takeEvery(DISPATCHES.SEARCH_KEYWORD_SAGA, searchKeyWords);
}
