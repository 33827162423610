// GraphicalLinks.tsx
import { useSelector } from "react-redux";
import { RootState } from "rootReducer";
// Components
import ToolTipComponent from "components/ToolTipComponent/ToolTipComponent";
import { TOOL_TIP_DIRECTION, TOOL_TIP_TEXT } from "components/ToolTipComponent/ToolTipDeclarations";
// Style
import {
  Wrapper,
  Container,
  LinksUpperRow,
  LinksLowerRow,
  LinkOuterContainer,
  LinkInnerContainer,
  Link1OuterContainer,
  LinkText,
  Link1,
  Link2,
  Link3,
  Link4,
  Link5,
  Link6,
  LinkArrowSmall,
  LinkTextLowerContainer,
  MobileContainer,
  MobileLeftColumn,
  MobileRightColumn,
  MobileLink1OuterContainer,
  MobileLinkOuterContainer,
  MobileWrapper,
  MobileLink1
} from "./GraphicalLinksStyles";

const GraphicalLinks = () => {
  // Hooks
  const { screenSize } = useSelector((state: RootState) => state.State);

  if (screenSize.width > 700) {
    return (
      <Wrapper>
        <Container>
          <LinksUpperRow>
            <ToolTipComponent
              toolTipTextProp={TOOL_TIP_TEXT.TJANSTER_OCH_PRODUKTER_INLOGG}
              toolTipDirectionProp={TOOL_TIP_DIRECTION.LEFTUP}
            >
              <Link1OuterContainer>
                <Link1
                  href="https://logp.fmv.se/tjansterprodukter/sidor/tjanster.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkInnerContainer>
                    <LinkText>
                      <br />
                      Tjänster &<br />
                      produkter A-Ö
                      <br />
                      <br />
                      FMV SFAP & <br />
                      &nbsp;ex-konto &#8594; <br />
                      <br />
                    </LinkText>
                  </LinkInnerContainer>
                </Link1>
              </Link1OuterContainer>
            </ToolTipComponent>
            <ToolTipComponent
              toolTipTextProp={TOOL_TIP_TEXT.TJANSTER_OCH_PRODUKTER_UTAN_INLOGG}
              toolTipDirectionProp={TOOL_TIP_DIRECTION.RIGHTUP}
            >
              <LinkOuterContainer>
                <Link6
                  href="https://logistikportalen.fmv.se/tjansterprodukter/Sidor/Startsidemall.aspx"
                  rel="noreferrer"
                >
                  <LinkInnerContainer>
                    <LinkText>
                      Tjänster &<br />
                      produkter
                      <br />
                      <LinkTextLowerContainer>
                        A-Ö&nbsp;<LinkArrowSmall>&#8594;</LinkArrowSmall>
                      </LinkTextLowerContainer>
                    </LinkText>
                  </LinkInnerContainer>
                </Link6>
              </LinkOuterContainer>
            </ToolTipComponent>
            <LinkOuterContainer>
              <Link2 href="https://logistikportalen.fmv.se/Pages/Nyhetsarkiv.aspx" rel="noreferrer">
                <LinkInnerContainer>
                  <LinkText>
                    &nbsp;Aktuellt
                    <br />
                    just nu &#8594;
                  </LinkText>
                </LinkInnerContainer>
              </Link2>
            </LinkOuterContainer>
          </LinksUpperRow>
          <LinksLowerRow>
            <ToolTipComponent
              toolTipTextProp={TOOL_TIP_TEXT.SOKCENTER_LINK}
              toolTipDirectionProp={TOOL_TIP_DIRECTION.LEFTUP}
            >
              <LinkOuterContainer>
                <Link3
                  href="https://logistikportalen.fmv.se/tjansterprodukter/Search/sidor/Searchstart.aspx"
                  rel="noreferrer"
                >
                  <LinkInnerContainer>
                    <LinkText>Sökcenter &#8594;</LinkText>
                  </LinkInnerContainer>
                </Link3>
              </LinkOuterContainer>
            </ToolTipComponent>
            <LinkOuterContainer>
              <Link4 href="https://logistikportalen.fmv.se/Pages/om.aspx" rel="noreferrer">
                <LinkInnerContainer>
                  <LinkText>
                    Om Logistik-
                    <br />
                    portalen &#8594;
                  </LinkText>
                </LinkInnerContainer>
              </Link4>
            </LinkOuterContainer>
            <LinkOuterContainer>
              <Link5 href="https://logistikportalen.fmv.se/kundtjanst/" rel="noreferrer">
                <LinkInnerContainer>
                  <LinkText>
                    &nbsp;&nbsp;&nbsp;Hjälp &
                    <br />
                    Expertstöd &#8594;
                  </LinkText>
                </LinkInnerContainer>
              </Link5>
            </LinkOuterContainer>
          </LinksLowerRow>
        </Container>
      </Wrapper>
    );
  } else {
    return (
      <MobileWrapper>
        <MobileContainer>
          <MobileLeftColumn>
            <MobileLink1OuterContainer>
              <MobileLink1
                href="https://logp.fmv.se/tjansterprodukter/sidor/tjanster.aspx"
                target="_blank"
                rel="noreferrer"
              >
                <LinkInnerContainer>
                  <LinkText>
                    <br />
                    Tjänster &<br />
                    produkter A-Ö
                    <br />
                    <br />
                    FMV SFAP & <br />
                    &nbsp;ex-konto &#8594; <br />
                    <br />
                  </LinkText>
                </LinkInnerContainer>
              </MobileLink1>
            </MobileLink1OuterContainer>
            <MobileLinkOuterContainer>
              <Link6 href="https://logistikportalen.fmv.se/tjansterprodukter/Sidor/Startsidemall.aspx" rel="noreferrer">
                <LinkInnerContainer>
                  <LinkText>
                    Tjänster &<br />
                    produkter
                    <br />
                    <LinkTextLowerContainer>
                      A-Ö&nbsp;<LinkArrowSmall>&#8594;</LinkArrowSmall>
                    </LinkTextLowerContainer>
                  </LinkText>
                </LinkInnerContainer>
              </Link6>
            </MobileLinkOuterContainer>
            <MobileLinkOuterContainer>
              <Link2 href="https://logistikportalen.fmv.se/Pages/Nyhetsarkiv.aspx" rel="noreferrer">
                <LinkInnerContainer>
                  <LinkText>
                    &nbsp;Aktuellt
                    <br />
                    just nu &#8594;
                  </LinkText>
                </LinkInnerContainer>
              </Link2>
            </MobileLinkOuterContainer>
          </MobileLeftColumn>
          <MobileRightColumn>
            <MobileLinkOuterContainer>
              <Link3
                href="https://logistikportalen.fmv.se/tjansterprodukter/Search/sidor/Searchstart.aspx"
                rel="noreferrer"
              >
                <LinkInnerContainer>
                  <LinkText>Sökcenter &#8594;</LinkText>
                </LinkInnerContainer>
              </Link3>
            </MobileLinkOuterContainer>
            <MobileLinkOuterContainer>
              <Link4 href="https://logistikportalen.fmv.se/Pages/om.aspx" rel="noreferrer">
                <LinkInnerContainer>
                  <LinkText>
                    Om Logistik-
                    <br />
                    portalen &#8594;
                  </LinkText>
                </LinkInnerContainer>
              </Link4>
            </MobileLinkOuterContainer>
            <MobileLinkOuterContainer>
              <Link5 href="https://logistikportalen.fmv.se/kundtjanst/" rel="noreferrer">
                <LinkInnerContainer>
                  <LinkText>
                    &nbsp;&nbsp;&nbsp;Hjälp &
                    <br />
                    Expertstöd &#8594;
                  </LinkText>
                </LinkInnerContainer>
              </Link5>
            </MobileLinkOuterContainer>
          </MobileRightColumn>
        </MobileContainer>
      </MobileWrapper>
    );
  }
};

export default GraphicalLinks;
