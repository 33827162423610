// import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "rootReducer";
// // Pages
// import StartPage from "pages/StartPage/StartPage";
// import StartPageMobile from "pages/StartPageMobile/StartPageMobile";
// // Style
// // import { GlobalStyles, lightTheme, darkTheme, ThemeButton } from "./styles/global";
// import { GlobalStyles, lightTheme, darkTheme } from "./styles/global";
// import { ThemeProvider } from "styled-components";

// const App: React.FC = () => {
//   const [theme, setTheme] = useState("light");
//   const isDarkTheme = theme === "dark";
//   // const toggleTheme = () => {
//   //   const updatedTheme = isDarkTheme ? "light" : "dark";
//   //   setTheme(updatedTheme);
//   //   localStorage.setItem("theme", updatedTheme);
//   // };

//   useEffect(() => {
//     const savedTheme = localStorage.getItem("theme");
//     const prefersDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
//     if (savedTheme && ["dark", "light"].includes(savedTheme)) {
//       setTheme(savedTheme);
//     } else if (prefersDark) {
//       setTheme("dark");
//     }
//   }, []);

//   // Hooks
//   const { screenSize } = useSelector((state: RootState) => state.State);

//   // const width = window.innerWidth;
//   // document.documentElement.clientWidth;
//   // document.body.clientWidth;

//   // const height = window.innerHeight;
//   // document.documentElement.clientHeight;
//   // document.body.clientHeight;

//   // console.log("width: ", width);
//   // console.log("height: ", height);

//   return (
//     <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
//       <>
//         <GlobalStyles />
//         {/* temp img for dev */}
//         {/* <img style={{ width: "100%", height: "100%" }} src={fmv} alt="fmv" /> */}
//         {/* temp button */}
//         {/* <button style={{ marginLeft: "2.4em", width: "3em", cursor: "pointer" }} onClick={toggleTheme}> */}
//         {/* <ThemeButton onClick={toggleTheme}>
//           {isDarkTheme ? (
//             <span aria-label="Light mode" role="img">
//               <FaAdjust />
//             </span>
//           ) : (
//             <span aria-label="Dark mode" role="img">
//               <FaAdjust />
//             </span>
//           )}
//         </ThemeButton> */}
//         {/* </button> */}
//         <div>{screenSize.width > 700 ? <StartPage /> : <StartPageMobile />}</div>
//         {/* <StartPage /> */}
//       </>
//     </ThemeProvider>
//   );
// };

import { useSelector } from "react-redux";
import { RootState } from "rootReducer";
import { useEffect } from "react";
// Pages
import StartPage from "pages/StartPage/StartPage";
import StartPageMobile from "pages/StartPageMobile/StartPageMobile";
// Style
import { GlobalStyles } from "styles/global";
import { MobileGlobal } from "styles/MobileGlobal";

const App: React.FC = () => {
  // Hooks
  const { screenSize } = useSelector((state: RootState) => state.State);

  useEffect(() => {
    const s4workspace = document.getElementById("s4-workspace");
    if (s4workspace) {
      console.log("log av s4workspace", s4workspace);
    } else {
      console.log("s4workspace hittades inte!");
    }

    const msCoreOverlay = document.getElementsByClassName("ms-core-overlay");
    if (msCoreOverlay) {
      console.log("log av s4workspace", msCoreOverlay);
    } else {
      console.log("msCoreOverlay hittades inte!");
    }
  }, []);

  if (screenSize.width !== 0 || null) {
    return (
      <div>
        {screenSize.width > 950 ? <GlobalStyles /> : <MobileGlobal />}
        <div>{screenSize.width > 950 ? <StartPage /> : <StartPageMobile />}</div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default App;
