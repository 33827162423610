import { fork } from "redux-saga/effects";
import { initializeApplicationSaga } from "state/sagas/InitializeApplicationSaga";
import { searchKeyWordsSaga } from "state/sagas/SearchKeyWordsSaga";
import { searchResultsSaga } from "state/sagas/SearchResultsSaga";
import { searchStatisticsSaga } from "state/sagas/SearchStatisticsSaga";

export default function* rootSaga() {
  yield fork(initializeApplicationSaga);
  yield fork(searchKeyWordsSaga);
  yield fork(searchResultsSaga);
  yield fork(searchStatisticsSaga);
}
