//EndPoints.ts
// SP endpoints

import { getEnvironment } from "./HelperFunctions";

const env = getEnvironment();

// /Startsida/_api/web/lists/getByTitle('KeyWords')/Items?
// export const keyWordsURL = `${env.listapi}/getByTitle('KeyWords')/Items?$select=id,KeyWords,link,description,icon`;
// export const keyWordsURL = `${env.listapi}/getByTitle('KeyWords')/Items?$select=Id,KeyWords,URL,Description`;
// /startsida/_api/web/lists/getByTitle('KeyWords')/Items?$select=Id,KeyWords,WebSiteURLIDIndex
export const keyWordsURL = `${env.listapi}/getByTitle('KeyWords')/Items?$select=Id,KeyWords,WebSiteURLIDIndex&$top=4999`;

// /startsida/_api/web/lists/getByTitle('Texts')/Items?$select=Id,Title`
// export const TextURL = `${env.listapi}/getByTitle('Texts')/Items?$select=ID,Title,text`;
export const TextURL = `${env.listapi}/getByTitle('Texts')/Items?$select=Id,Title,Text`;

// /Startsida/_api/web/lists/getByTitle('WebSiteURLs')/Items?$select=Id,URL
// /Startsida/_api/web/lists/getByTitle('WebSiteURLs')/Items?$select=Id,Title,URL
export const WebSitesURL = `${env.listapi}/getByTitle('WebSiteURLs')/Items?$select=Id,Title,URL,WebSiteURLIDIndex,SiteImage,Protected&$top=4999`;

// Tooltips
// /Startsida/_api/web/lists/getByTitle('ToolTips')/Items?$select=Id,Description&$top=4999
export const ToolTipsURL = `${env.listapi}/getByTitle('ToolTips')/Items?$select=Id,Title,Description&$top=4999`;

// SearchStatistics
// /Startsida/_api/web/lists/getByTitle('SearchStatistics')/Items?$select=SelectedSearchResult,SelectedSearchWord,TimesSearched,TimesSelected&$top=4999
export const SearchStatistics = `${env.listapi}/getByTitle('SearchStatistics')/Items?$select=ID,SelectedSearchResult,SelectedSearchWord,TimesSelected&$top=4999`;
