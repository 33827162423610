// global.ts
import styled, { createGlobalStyle } from "styled-components";

// Interfaces required to make styled-components play nicely with TypeScript
// export interface theme {
//   body: string;
//   text: string;
//   footerBackgroundColor: string;
//   footerBorder: string;
// }

// export interface DefaultTheme {
//   theme: theme;
// }

export const GlobalStyles = createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
        font-family: "Calibri", sans-serif;
        font-size: 13px;

        h1,
        .h1 {
          font-size: 36px;
        }
        h2,
        .h2 {
          font-size: 30px;
        }
        h3,
        .h3 {
          font-size: 24px;
        }
        h4,
        .h4 {
          font-size: 18px;
        }
        h5,
        .h5 {
          font-size: 14px;
        }
        h6,
        .h6 {
          font-size: 12px;
        }
        .table td {
          padding: 5px;
        }
      }
      
    #root{
        margin:0 auto;
    }
    .ms-siteactions-root {
      display: none !important;
    }
    .ms-webpartzone-cell {
      margin: 0 0 0 0 !important;
    }
    #contentBox {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    }
    #button {
      min-height: 0 !important;
      min-width: 0 !important;
    }
  body {
        h1,
        .h1 {
          font-size: 36px;
        }
        h2,
        .h2 {
          font-size: 30px;
        }
        h3,
        .h3 {
          font-size: 24px;
        }
        h4,
        .h4 {
          font-size: 18px;
        }
        h5,
        .h5 {
          font-size: 14px;
        }
        h6,
        .h6 {
          font-size: 12px;
        }
        .table td {
          padding: 5px;
        }
      }

  a:hover {
    text-decoration: none !important;
  }
`;

export const lightTheme = {
  // body: "#f1f1f1",
  // text: "#121620"
  // RGBA(224, 222, 216) FMV Grafisk profil basfärg ljust
  body: "RGBA(224, 222, 216, 0.2)",
  text: "#121620",
  footerBackgroundColor: "#c3c0c0",
  footerBorder: "0.2em solid #c3c0c0"
};

export const darkTheme = {
  // body: "#121620",
  // text: "#f1f1f1"
  // RGBA( 28, 30, 28) FMV Grafisk profil basfärg svart
  body: "RGBA( 28, 30, 28)",
  text: "#f1f1f1",
  footerBackgroundColor: "RGBA(86, 70, 69, 95, 0.4)",
  footerBorder: "0.2em solid #c3c0c0"
};

export const ThemeButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 3em;
  margin-left: 2.4em;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
