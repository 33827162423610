/* eslint-disable @typescript-eslint/no-explicit-any */
// SelectSearchBar.tsx
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "rootReducer";
import Select from "react-select";
// Actions
import * as Action from "state/actions/Actions";
// Components
import ToolTipComponent from "components/ToolTipComponent/ToolTipComponent";
import { TOOL_TIP_DIRECTION, TOOL_TIP_TEXT } from "components/ToolTipComponent/ToolTipDeclarations";
// Interfaces
import { IOptionType } from "state/interfaces/Interfaces";
// Style
import { Wrapper, Container, MobileContainer } from "./SelectSearchBarStyle";
// import { Wrapper, Container } from "./Style";

const SelectSearchBar = () => {
  const dispatch = useDispatch();

  // Hooks
  const { searchInput } = useSelector((state: RootState) => state.State);
  const { matchingKeyWordsArray } = useSelector((state: RootState) => state.State);
  const { screenSize } = useSelector((state: RootState) => state.State);

  const options: IOptionType[] = matchingKeyWordsArray.map(word => ({
    label: word.toUpperCase(),
    value: word.toUpperCase()
  }));

  // Get option label
  const getOptionLabel = (option: IOptionType) => option.label;

  // Get option value
  const getOptionValue = (option: IOptionType) => option.value;

  // Handels user typed input
  const handleInputChange = (inputValue: string) => {
    console.log(`User typed: ${inputValue}`);

    // dispatch(Action.searchInputAction(inputValue));
    dispatch(Action.searchKeyWordsSaga(inputValue));
  };

  useEffect(() => {
    // Disables zoom for input field
    const inputField = document.getElementById("selectInput");
    // const inputField = document.getElementsByClassName("customSelect");
    // console.log("inputField: ", inputField);

    if (inputField !== null) {
      inputField.addEventListener("focus", function () {
        disableZoom();
      });
    } else {
      console.log("inputField not found.");
    }
  }, []);

  const handleChange = (selectedOption: IOptionType | null) => {
    if (selectedOption === null) {
      // Reset for state
      dispatch(Action.searchInputResetAction());
      // Reset to clear a previous search result
      dispatch(Action.setUserSelectedInputKeyWord({ label: "", value: "" }));
    }
    if (selectedOption !== null && selectedOption.label !== "") {
      dispatch(Action.searchResultsSaga(selectedOption.label));
      dispatch(Action.setUserSelectedInputKeyWord(selectedOption));
    }
  };

  function disableZoom() {
    const viewportMetaTag = document.querySelector("meta[name='viewport']");
    if (viewportMetaTag !== null) {
      viewportMetaTag.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      );
    }
  }

  // Style for Select component
  // const customStyles = (isDisabled = false) => {
  const customStyles = () => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      control: (provided: any) => ({
        ...provided
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      valueContainer: (provided: any) => ({
        ...provided,
        height: "40px",
        padding: "0 6px",
        border: "1px solid black",
        boxShadow: "1px 1px 2px #797979"
      }),

      // input: (provided: any) => ({
      //   ...provided,
      //   margin: "0px"
      // }),
      indicatorSeparator: () => ({
        display: "none"
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: "30px"
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        display: "none"
      }),
      menu: (provided: any) => ({
        ...provided,
        color: "black",
        opacity: searchInput.length > 1 ? "1" : "0"
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearIndicator: (provided: any) => ({
        ...provided,
        marginTop: "8px",
        height: "40px",
        borderTop: "1px solid black",
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
        boxShadow: "1px 1px 2px #797979",
        transform: "translate(0px, 1px)",
        color: "black"
      }),
      /* Prevent text selection when input field is focused */
      input: (provided: any) => ({
        ...provided,
        margin: "0px",
        "&:focus": {
          webkitUserSelect: "none",
          mozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none"
        }
      })
    };
  };

  if (screenSize.width > 700) {
    return (
      <Wrapper>
        <ToolTipComponent
          toolTipTextProp={TOOL_TIP_TEXT.VAD_VILL_DU_GORA_IDAG}
          toolTipDirectionProp={TOOL_TIP_DIRECTION.RIGHTUP}
        >
          <Container>
            <Select
              id="selectInput"
              className="customSelect"
              styles={customStyles()}
              placeholder="Vad vill du ha hjälp med att hitta?"
              onChange={handleChange}
              onInputChange={handleInputChange}
              // Set the "tabSelectsValue" prop to fale to allow capturing Enter key
              tabSelectsValue={false}
              isClearable={true}
              options={options}
              // Set the "getOptionLabel" and "getOptionValue" props to display the correct labels and values
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              noOptionsMessage={() => "Sökresultat saknas"}
            />
          </Container>
        </ToolTipComponent>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <MobileContainer>
          <Select
            id="selectInput"
            className="customSelect"
            styles={customStyles()}
            placeholder="Vad vill du ha hjälp med att hitta?"
            onChange={handleChange}
            onInputChange={handleInputChange}
            // Set the "tabSelectsValue" prop to fale to allow capturing Enter key
            tabSelectsValue={false}
            isClearable={true}
            options={options}
            // Set the "getOptionLabel" and "getOptionValue" props to display the correct labels and values
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            noOptionsMessage={() => "Sökresultat saknas"}
          />
        </MobileContainer>
      </Wrapper>
    );
  }

  // return (
  //   <Wrapper>
  //     <ToolTipComponent
  //       toolTipTextProp={TOOL_TIP_TEXT.VAD_VILL_DU_GORA_IDAG}
  //       toolTipDirectionProp={TOOL_TIP_DIRECTION.RIGHTUP}
  //     >
  //       <Container>
  //         <Select
  //           className="customSelect"
  //           styles={customStyles()}
  //           placeholder="Vad vill du ha hjälp med att hitta?"
  //           onChange={handleChange}
  //           onInputChange={handleInputChange}
  //           // Set the "tabSelectsValue" prop to fale to allow capturing Enter key
  //           tabSelectsValue={false}
  //           isClearable={true}
  //           options={options}
  //           // Set the "getOptionLabel" and "getOptionValue" props to display the correct labels and values
  //           getOptionLabel={getOptionLabel}
  //           getOptionValue={getOptionValue}
  //           noOptionsMessage={() => "Sökresultat saknas"}
  //         />
  //       </Container>
  //     </ToolTipComponent>
  //   </Wrapper>
  // );
};

export default SelectSearchBar;
