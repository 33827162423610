// StartPageMobile.tsx
import { useSelector } from "react-redux";
import { RootState } from "rootReducer";
// Components
import SelectSearchBar from "components/selectSearchBar/SelectSearchBar";
import QuickLinks from "components/quickLinks/QuickLinks";
import GraphicalLinks from "components/graphicalLinks/GraphicalLinks";
import SquareLinks from "components/squareLinks/SquareLinks";
import SearchResultCarousel from "components/searchResultCarousel/SearchResultCarousel";
// Style
import {
  Wrapper,
  Container,
  TextContainer,
  Text,
  UL,
  LI,
  ReadMoreLink,
  ImgContainer,
  LogoTypeContainer,
  ImgInnerContainer,
  GraphicalLinksContainer,
  SquareLinksContainer,
  QuickLinksContainer
} from "./StartPageMobileStyle";

const StartPageMobile = () => {
  // Hooks
  const { startPageText } = useSelector((state: RootState) => state.State);
  const { searchResult } = useSelector((state: RootState) => state.State);

  return (
    <Wrapper>
      <Container>
        <ImgContainer>
          <ImgInnerContainer>{<LogoTypeContainer />}</ImgInnerContainer>
        </ImgContainer>
        <SelectSearchBar />
        <SearchResultCarousel />
        {/* Hides the start page text if there is a search result */}
        {searchResult.length > 0 ? (
          <div />
        ) : (
          <TextContainer>
            <Text>{startPageText.text_top}</Text>
            <UL>
              <LI>Försvarslogistik</LI>
              <LI>Integrerat Logistikstöd (ILS)</LI>
              <LI>FMV Produktlivscykelhantering (FMV PLM)</LI>
            </UL>
            <Text>
              {/* &nbsp; to add space between text and link */}
              {startPageText.text_bottom}&nbsp;
              <ReadMoreLink href="https://logistikportalen.fmv.se/Pages/om.aspx" target="_blank" rel="noreferrer">
                här
              </ReadMoreLink>
            </Text>
          </TextContainer>
        )}
        <GraphicalLinksContainer>
          <GraphicalLinks />
        </GraphicalLinksContainer>
        <SquareLinksContainer>
          <SquareLinks />
        </SquareLinksContainer>
        <QuickLinksContainer>
          <QuickLinks />
        </QuickLinksContainer>
      </Container>
    </Wrapper>
  );
};

export default StartPageMobile;
