//StartPage.tsx
import { useSelector } from "react-redux";
import { RootState } from "rootReducer";
// Components
import SelectSearchBar from "components/selectSearchBar/SelectSearchBar";
import QuickLinks from "components/quickLinks/QuickLinks";
import GraphicalLinks from "components/graphicalLinks/GraphicalLinks";
import SquareLinks from "components/squareLinks/SquareLinks";
import SearchResultCarousel from "components/searchResultCarousel/SearchResultCarousel";
import ToolTipComponent from "components/ToolTipComponent/ToolTipComponent";
import { TOOL_TIP_DIRECTION, TOOL_TIP_TEXT } from "components/ToolTipComponent/ToolTipDeclarations";
import VideoLink from "components/videoLink/VideoLink";
// Style
import {
  Wrapper,
  Container,
  InnerUpperRowContainer,
  InnerLowerRowContainer,
  LeftUpperColumnContainer,
  RightUpperColumnContainer,
  LeftLowerColumnContainer,
  RightLowerColumnContainer,
  TextContainer,
  Text,
  UL,
  LI,
  ReadMoreLink,
  ImgContainer,
  LogoTypeContainer,
  ImgInnerContainer,
  Heading,
  VideoLinkContainer
} from "./StartPageStyle";

const StartPage = () => {
  // Hooks
  const { startPageText } = useSelector((state: RootState) => state.State);
  const { searchResult } = useSelector((state: RootState) => state.State);

  return (
    <Wrapper>
      <Container>
        <ImgContainer>
          <ImgInnerContainer>
            <Heading>Välkommen till</Heading>
            <LogoTypeContainer />
            <VideoLinkContainer>
              <VideoLink src="https://logistikportalen.fmv.se/Delade%20dokument/Logistikportalen_info.mp4" />
            </VideoLinkContainer>
          </ImgInnerContainer>
        </ImgContainer>
        <InnerUpperRowContainer>
          <LeftUpperColumnContainer>
            <SelectSearchBar />
            <SearchResultCarousel />
            {/* Hides the start page text if there is a search result */}
            {searchResult.length > 0 ? (
              <div />
            ) : (
              <TextContainer>
                <Text>{startPageText.text_top}</Text>
                <UL>
                  <LI>Försvarslogistik</LI>
                  <LI>Integrerat Logistikstöd (ILS)</LI>
                  <LI>FMV Produktlivscykelhantering (FMV PLM)</LI>
                </UL>
                <Text>
                  {/* &nbsp; to add space between text and link */}
                  {startPageText.text_bottom}&nbsp;
                  <ReadMoreLink href="https://logistikportalen.fmv.se/Pages/om.aspx" target="_blank" rel="noreferrer">
                    här
                  </ReadMoreLink>
                </Text>
              </TextContainer>
            )}
          </LeftUpperColumnContainer>
          <RightUpperColumnContainer>
            <GraphicalLinks />
          </RightUpperColumnContainer>
        </InnerUpperRowContainer>
        <InnerLowerRowContainer>
          <LeftLowerColumnContainer>
            <ToolTipComponent
              toolTipTextProp={TOOL_TIP_TEXT.FMVS_STOD_INOM_FORSVARSLOGISTIK}
              toolTipDirectionProp={TOOL_TIP_DIRECTION.RIGHTUP}
            >
              <SquareLinks />
            </ToolTipComponent>
          </LeftLowerColumnContainer>
          <RightLowerColumnContainer>
            <QuickLinks />
          </RightLowerColumnContainer>
        </InnerLowerRowContainer>
      </Container>
    </Wrapper>
  );
};

export default StartPage;
